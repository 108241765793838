import { createSharedComposable } from '@vueuse/core';
import { EstimateContentType } from '~/types/enums';
import type { Quote, WorkItem } from '~/types/interfaces';
import type { Product } from '~/types/quote';
import { useStorage } from "@vueuse/core";

const _useQuote = () => {
	/**
	 * Calculates the total cost before or after discount for the given articles.
	 * @param {Product[]} articles - Array of product objects.
	 * @param {boolean} includeDiscount - Flag indicating whether to include discount in the calculation.
	 * @returns {number} The calculated total cost.
	 */
	const calculateTotalWorkHt = (articles?: Product[], includeDiscount?: boolean): number => {
		if(!articles) return 0

		return articles.reduce((total, product) => {

			const worksTotal = (product.works || []).reduce((workTotal, work) => {
				const calculatedTime = parseFloat(work.calculatedTime) || 0
				return workTotal + (calculatedTime * work.rate.value)
			}, 0)
			
			const variousTimesTotal = product.productTypeQuoteId === EstimateContentType.VariousTimes
				? product.price.unitPriceBeforePromoHT * product.quantity * (includeDiscount ? (1 - product.price.discount / 100) : 1)
				: 0

			const laborTimeTotal = product.productTypeQuoteId === EstimateContentType.LaborTime && product.rate
				? product.rate.value * product.quantity * (includeDiscount ? (1 - product.price.discount / 100) : 1)
				: 0

			return total + worksTotal + variousTimesTotal + laborTimeTotal
		}, 0)
	}

	/**
	 * Calculates the total time from works and quantities of products.
	 * @param {Product[]} articles - Array of product objects.
	 * @returns {number} The calculated total time.
	 */
	const calculateTotalTime = (articles?: Product[]): number => {
		if(!articles) return 0

		return articles.reduce((total: number, product: Product) => {
			const worksTimeTotal = (product.works || []).reduce((workTotal: number, work: WorkItem) => {
				const calculatedTime = parseFloat(work.calculatedTime as unknown as string) || 0
				return workTotal + calculatedTime
			}, 0)
		
			const additionalQuantity = product.productTypeQuoteId === EstimateContentType.VariousTimes || product.productTypeQuoteId === EstimateContentType.LaborTime
				? product.quantity
				: 0
		
			return total + worksTimeTotal + additionalQuantity
		}, 0)
	}

	/**
	 * Calculates the total price for parts.
	 * @param {Product[]} articles - Array of product objects.
	 * @param {boolean} includeDiscount - Flag indicating whether to include discount in the calculation.
	 * @returns {number} The calculated total price.
	 */
	const calculateTotalPartsHt = (articles?: Product[], includeDiscount?: boolean): number => {
		if(!articles) return 0

		return articles
		.filter(product => product.productTypeQuoteId !== EstimateContentType.VariousTimes && product.productTypeQuoteId !== EstimateContentType.Plan)
		.reduce((total: number, product: Product) => {
			const price = product.price.unitPriceBeforePromoHT * product.quantity * (product.conditionnement ?? 1)
			const discountedPrice = price * (includeDiscount ? (1 - (product.price.discount || 0) / 100) : 1)
			return total + discountedPrice
		}, 0)
	}

	/**
	 * Calculates the total price for products of type "plan".
	 * @param {Product[]} articles - Array of product objects.
	 * @param {boolean} includeDiscount - Flag indicating whether to include discount in the calculation.
	 * @returns {number} The calculated total price for "plan" products.
	 */
	const calculateTotalDiversHt = (articles?: Product[], includeDiscount?: boolean): number => {
		if(!articles) return 0

		return articles
		.filter(product => product.productTypeQuoteId === EstimateContentType.Plan)
		.reduce((total: number, product: Product) => {
			const basePrice = product.price.unitPriceBeforePromoHT * product.quantity * (product.conditionnement ?? 1)
			const discountedPrice = basePrice * (includeDiscount ? (1 - (product.price.discount || 0) / 100) : 1 )
			return total + discountedPrice
		}, 0)
	}

	/**
	 * Adds VAT (Value Added Tax) to the given price.
	 * @param {number} price - The initial price before VAT.
	 * @param {number} [vat=20] - The VAT percentage to apply (default is 20%).
	 * @returns {number} The price including VAT.
	 */
	const addVat = (price: number, vat: number = 20): number => {
		return price * (1 + vat / 100)
	}
	
	/**
	 * Calculates the promotion percentage based on the initial and reduced price.
	 * @param {number} initialPrice - The original price of the product.
	 * @param {number} reducedPrice - The discounted price of the product.
	 * @returns {number} The promotion percentage.
	 */
	const calculatePromoPercentage = (initialPrice: number, reducedPrice: number): number => {
		const promo = initialPrice - reducedPrice
		return (promo / initialPrice) * 100
	}

	/**
	 * Calculates the total time for products based on a specific rate type.
	 * This includes both custom time (from the products) and work time.
	 * 
	 * @param {Product[]} articles - Array of product objects.
	 * @param {number} rateTypeId - The id of rate to filter by.
	 * @returns {number} The total calculated time based on the rate type.
	 */
	const calculateTotalTimeByRate = (articles: Product[], rateTypeId: number): number => {
		if(!articles) return 0

		const totalCustomTime = articles 
			.filter(product => product.rate && product.rate.idType === rateTypeId)
			.reduce((total, product) => total + product.quantity, 0); 
	
		const totalTime = articles
			.flatMap(product => product.works ?? [])
			.filter(work => work.rate?.idType === rateTypeId)
			.reduce((total, work) => total + (work.calculatedTime ?? 0), 0);
	
		return totalTime + totalCustomTime
	}
	
	/**
	 * Calculates the total price (HT) for products and work time based on a specific rate type.
	 * This includes both the custom time price (from the products) and the work time price.
	 * 
	 * @param {Product[]} articles - Array of product objects.
	 * @param {number} rateTypeId - The id of rate to filter by.
	 * @returns {number} The total calculated price HT based on the rate type.
	 */
	const calculateTotalTimeByRateHt = (articles: Product[], rateTypeId: number): number => {	
		if(!articles) return 0
		const totalCustomTimePriceHt = articles 
			.filter(product => product.rate && product.rate.idType === rateTypeId)
			.reduce((total, product) => total + (product.rate?.value ?? 0 * product.quantity), 0);
	
		const totalRatePriceHt = articles
			.flatMap(product => product.works ?? [])
			.filter(work => work.rate?.idType === rateTypeId && work.rate && work.calculatedTime)
			.reduce((total, work) => total + (work.rate.value * work.calculatedTime), 0)
	
		return totalCustomTimePriceHt + totalRatePriceHt
	}

	const currentProductIndexInStoredQuote = (storedQuote: Quote, currentProduct: Product) => {
		return storedQuote.articles.findIndex(product => product.uniqueReference === currentProduct.uniqueReference);
	}

	/**
	 * Adds a product to the stored quote. If the product is already present, it updates the quantity.
	 * Otherwise, it adds the product with default quote type and tax values.
	 * 
	 * @param {Product} productToAdd - The product to be added to the quote.
	 * @returns {Promise<void>} A promise that resolves once the product is added or updated in the quote.
	 */
	const addToQuote = async (productToAdd: Product): Promise<void> => {
		const defaultProductTypeQuoteId = 1
		const defaultProductTaxeQuoteId = 1
	  
		const storedQuote = useStorage<Quote>("quote", <Quote>{}, sessionStorage)
		//@ts-ignore
		if (!storedQuote.value.articles) storedQuote.value.articles = reactive(<Product[]>[])
		const currentProductIndex = currentProductIndexInStoredQuote(storedQuote.value, productToAdd)
	  
		if (currentProductIndex !== -1) {
		  	storedQuote.value.articles[currentProductIndex].quantity += productToAdd.quantity
		} else {
			//@ts-ignore
			storedQuote.value.articles.push(productToAdd)
		}
	  
		productToAdd.productTypeQuoteId = defaultProductTypeQuoteId
		productToAdd.productTaxQuoteId = defaultProductTaxeQuoteId
	}

	return {
		calculateTotalWorkHt,
		calculateTotalTime,
		calculateTotalPartsHt,
		calculateTotalDiversHt,
		addVat,
		calculatePromoPercentage,
		calculateTotalTimeByRate,
		calculateTotalTimeByRateHt,
		addToQuote
	};
};
export const useQuote = createSharedComposable(_useQuote)